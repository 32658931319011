import React from 'react'

const ButtonArrow = () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
        <path d='M10.5316 0.935059L1 10.4667' stroke='white' strokeWidth='1.76271' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.58984 0.935303H10.5328V8.87831' stroke='white' strokeWidth='1.76271' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    );
  };
export default ButtonArrow