import { configureStore } from '@reduxjs/toolkit';
import companiesReducer from './slices/companiesSlice';
import jobsReducer from './slices/jobsSlice';
import eventsReducer from './slices/eventsSlice';

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    jobs: jobsReducer,
    events: eventsReducer,
  },
});

export default store;
