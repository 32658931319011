import React, { useState } from "react";
import { useCookies } from "react-cookie";
import Modal from "components/Modal";
import Button from "components/Button/Button";
import cheggl from "assets/images/ChegglLogo.png";
import "./CookieConsent.scss";

const CookieConsentModal = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);
  const [showSettings, setShowSettings] = useState(false);
  const [preferences, setPreferences] = useState({
    essential: true,
    functional: false,
    statistics: false,
    marketing: false,
  });

  const generateUID = () => {
    return "xxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getConsentData = (preferences) => {
    const consents = {
      essential: ["borlabs-cookie"],
      ...(preferences.statistics && { statistics: ["lead-data"] }),
      ...(preferences.functional && {
        functional: ["some-functional-cookie"],
      }),
      ...(preferences.marketing && { marketing: ["some-marketing-cookie"] }),
      ...(preferences.external_media && {
        external_media: [
          "facebook",
          "googlemaps",
          "instagram",
          "twitter",
          "vimeo",
        ],
      }),
    };

    return {
      consents,
      domainPath: "cheggl.com/",
      expires: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toGMTString(),
      uid: generateUID(),
      version: "1",
    };
  };

  const handleAcceptAll = () => {
    const consentData = getConsentData({
      essential: true,
      functional: true,
      statistics: true,
      marketing: true,
      external_media: true,
    });
    setCookie("cookieConsent", JSON.stringify(consentData), { path: "/" });
    setShowBanner(false);
  };

  const handleReject = () => {
    const consentData = getConsentData({
      essential: true,
    });
    setCookie("cookieConsent", JSON.stringify(consentData), { path: "/" });
    setShowBanner(false);
  };

  const handleSettingsChange = (event) => {
    const { name, checked } = event.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSaveSettings = () => {
    const consentData = getConsentData(preferences);
    setCookie("cookieConsent", JSON.stringify(consentData), { path: "/" });
    setShowBanner(false);
  };

  const closeModal = () => {
    setShowBanner(false);
  };

  const openSettings = () => {
    setShowSettings(true);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-modal">
      <Modal isOpen={showBanner} onClose={closeModal} hideCancelSvg={true}>
        <img src={cheggl} alt="Cheggl Logo" />

        {!showSettings && (
          <>
            <h2>Privatsphäre-Einstellungen</h2>
            <p>
              Wir verwenden Cookies und ähnliche Technologien auf unserer
              Website und verarbeiten personenbezogene Daten von dir (z.B.
              IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren,
              Medien von Drittanbietern einzubinden oder Zugriffe auf unsere
              Website zu analysieren. Die Datenverarbeitung kann erst in Folge
              gesetzter Cookies stattfinden. Wir teilen diese Daten mit Dritten,
              die wir in den Privatsphäre-Einstellungen benennen. Die
              Datenverarbeitung kann mit deiner Einwilligung oder auf Basis
              eines berechtigten Interesses erfolgen, dem du in den
              Privatsphäre-Einstellungen widersprechen kannst. Du hast das
              Recht, nicht einzuwilligen und deine Einwilligung zu einem
              späteren Zeitpunkt zu ändern oder zu widerrufen. Weitere
              Informationen zur Verwendung deiner Daten findest du in unserer&nbsp;
              <a href="https://cheggl.com/datenschutz">Datenschutzerklärung</a>.
            </p>
            <div className="cookiesBtn">
              <Button text="Akzeptiere alle" onClick={handleAcceptAll} />
              <Button 
                text="Weiter ohne Einwilligung"
                onClick={handleReject}
              />
              <Button
                text="Individuelle Einstellungen"
                onClick={openSettings}
              />
            </div>
          </>
        )}
        {showSettings && (
          <div className="cookie-settings">
            <img className="mt-100" src={cheggl} alt="Cheggl Logo" />

            <h3>Individuelle Einstellungen</h3>
            <label>
              <input
                type="checkbox"
                name="essential"
                checked={preferences.essential}
                disabled
              />
              Essenziell
              <p>
                Essential Services sind für die grundlegende Funktionalität der
                Website erforderlich. Sie enthalten nur technisch notwendige
                Services. Diesen Services kann nicht widersprochen werden.
              </p>
            </label>
            <label>
              <input
                type="checkbox"
                name="functional"
                checked={preferences.functional}
                onChange={handleSettingsChange}
              />
              Funktional
              <p>
                Funktionale Services sind notwendig, um über die wesentliche
                Funktionalität der Website hinausgehende Features wie hübschere
                Schriftarten, Videowiedergabe oder interaktive Web 2.0-Features
                bereitzustellen. Inhalte von z.B. Video- und Social
                Media-Plattformen sind standardmäßig gesperrt und können
                zugestimmt werden. Wenn dem Service zugestimmt wird, werden
                diese Inhalte automatisch ohne weitere manuelle Einwilligung
                geladen.
              </p>
            </label>
            <label>
              <input
                type="checkbox"
                name="statistics"
                checked={preferences.statistics}
                onChange={handleSettingsChange}
              />
              Statistiken
              <p>
                Statistik-Services werden benötigt, um pseudonymisierte Daten
                über die Besucher der Website zu sammeln. Die Daten ermöglichen
                es uns, die Besucher besser zu verstehen und die Website zu
                optimieren.
              </p>
            </label>
            <label>
              <input
                type="checkbox"
                name="marketing"
                checked={preferences.marketing}
                onChange={handleSettingsChange}
              />
              Marketing
              <p>
                Marketing Services werden von uns und Dritten genutzt, um das
                Verhalten einzelner Nutzer aufzuzeichnen, die gesammelten Daten
                zu analysieren und z.B. personalisierte Werbung anzuzeigen.
                Diese Services ermöglichen es uns, Nutzer über mehrere Websites
                hinweg zu verfolgen.
              </p>
            </label>
            <Button text="Speichern" onClick={handleSaveSettings} />
            <Button 
                className=" mt-10"
              text="Zurück"
              onClick={() => setShowSettings(false)}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CookieConsentModal;
