import React from "react";
import "./Modal.scss";
import CrossSvg from "assets/svgs/Cross";
const Modal = ({ isOpen, onClose, children, modalClass, hideCancelSvg }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className={`modal ${modalClass && modalClass}`}>
            {!hideCancelSvg && (
              <button className="close-btn" onClick={onClose}>
                <CrossSvg />
              </button>
            )}

            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
