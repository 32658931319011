import React from 'react'

const UserSvg = ({ fill }) => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'>
        <path
          d='M13.5461 6.23622C12.6381 6.23622 11.7505 6.50548 10.9955 7.00995C10.2405 7.51442 9.65204 8.23144 9.30455 9.07035C8.95707 9.90925 8.86615 10.8324 9.0433 11.7229C9.22044 12.6135 9.6577 13.4315 10.2998 14.0736C10.9418 14.7157 11.7599 15.1529 12.6505 15.3301C13.541 15.5072 14.4641 15.4163 15.303 15.0688C16.1419 14.7213 16.859 14.1329 17.3634 13.3779C17.8679 12.6229 18.1372 11.7353 18.1372 10.8273C18.1372 9.60964 17.6535 8.44189 16.7925 7.58091C15.9315 6.71992 14.7637 6.23622 13.5461 6.23622ZM13.5461 13.5819C13.0013 13.5819 12.4687 13.4203 12.0157 13.1176C11.5627 12.815 11.2097 12.3848 11.0012 11.8814C10.7927 11.3781 10.7381 10.8242 10.8444 10.2899C10.9507 9.75552 11.2131 9.26469 11.5983 8.87945C11.9835 8.49421 12.4744 8.23185 13.0087 8.12557C13.5431 8.01928 14.0969 8.07383 14.6003 8.28232C15.1036 8.49081 15.5338 8.84388 15.8365 9.29687C16.1392 9.74987 16.3007 10.2824 16.3007 10.8273C16.3 11.5576 16.0096 12.2578 15.4931 12.7743C14.9767 13.2907 14.2765 13.5812 13.5461 13.5819Z'
          fill={fill ? fill : '#5315B5'}
        />
        <path
          d='M13.5463 0.72699C11.0039 0.72699 8.5185 1.48092 6.40452 2.89343C4.29054 4.30595 2.64289 6.31361 1.66993 8.66254C0.696976 11.0115 0.442406 13.5962 0.938416 16.0898C1.43443 18.5834 2.65874 20.8739 4.45653 22.6717C6.25432 24.4695 8.54485 25.6938 11.0385 26.1898C13.5321 26.6858 16.1168 26.4312 18.4657 25.4583C20.8146 24.4853 22.8223 22.8377 24.2348 20.7237C25.6473 18.6097 26.4012 16.1244 26.4012 13.5819C26.3973 10.1738 25.0417 6.90632 22.6318 4.4964C20.2219 2.08648 16.9545 0.730878 13.5463 0.72699V0.72699ZM8.03708 23.1101V21.8458C8.0378 21.1154 8.32826 20.4152 8.84469 19.8988C9.36113 19.3823 10.0614 19.0919 10.7917 19.0911H16.3009C17.0313 19.0919 17.7315 19.3823 18.248 19.8988C18.7644 20.4152 19.0548 21.1154 19.0556 21.8458V23.1101C17.3837 24.0864 15.4824 24.6008 13.5463 24.6008C11.6103 24.6008 9.70898 24.0864 8.03708 23.1101V23.1101ZM20.8846 21.7778C20.8663 20.5734 20.3756 19.4244 19.5183 18.5784C18.6609 17.7324 17.5055 17.257 16.3009 17.2547H10.7917C9.58718 17.257 8.43176 17.7324 7.57438 18.5784C6.717 19.4244 6.2263 20.5734 6.20801 21.7778C4.54289 20.291 3.36866 18.3335 2.84078 16.1645C2.31291 13.9955 2.4563 11.7173 3.25196 9.63164C4.04762 7.54595 5.45803 5.75112 7.29642 4.48482C9.13481 3.21852 11.3145 2.54047 13.5468 2.54047C15.7791 2.54047 17.9587 3.21852 19.7971 4.48482C21.6355 5.75112 23.0459 7.54595 23.8416 9.63164C24.6373 11.7173 24.7807 13.9955 24.2528 16.1645C23.7249 18.3335 22.5507 20.291 20.8856 21.7778H20.8846Z'
          fill={fill ? fill : '#5315B5'}
        />
      </svg>
    );
  };

export default UserSvg