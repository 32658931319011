import React from "react";
import "./Button.scss";
const Button = ({
  className,
  text,
  onClick,
  disabled,
  icon,
  iconPosition,
  type,
  iconStyle,
}) => {
  return (
    <button
      className={`button ${className || ""}`}
      onClick={onClick}
      disabled={disabled}
      type={type || "button"}
    >
      {icon && iconPosition === "left" && (
        <span className={`button-icon left ${iconStyle}`}>{icon}</span>
      )}
      {text}
      {icon && iconPosition === "right" && (
        <span className={`button-icon right ${iconStyle}`}>{icon}</span>
      )}
    </button>
  );
};

export default Button;
