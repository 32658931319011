import { createSlice } from '@reduxjs/toolkit';

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    eventData: [],
  },
  reducers: {
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
  },
});

export const { setEventData } = eventsSlice.actions;

export default eventsSlice.reducer;
