import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Search.scss";
import { searchCompanies, searchSuggestions } from "Api/Company";
import { searchSuggestionsQuery, searchWithoutIndustry } from "components/Constant/search/queries";
const SearchInput = ({
  handleKeyPress,
  name = "",
  icon,
  placeholder,
  classIcon,
  value,
  isError,
  error,
  type = "search",
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchedSuggestions, setSearchedSuggestions] = useState([]);
  const [renderDropDown, setRenderDropDown] = useState(false);
  const history = useHistory();
  const extractSuggestions = (suggest) => {
    let extractedSuggestions = [];
    suggest["name-suggest"]?.length &&
      suggest["name-suggest"][0]?.options?.forEach((option) => {
        extractedSuggestions.push(option?.text);
      });
    // suggest["services-suggest"]?.length &&
    //   suggest["services-suggest"][0]?.options?.forEach((option) => {
    //     extractedSuggestions.push(option?.text);
    //   });
    // suggest["tagLine-suggest"]?.length &&
    //   suggest["tagLine-suggest"][0]?.options?.forEach((option) => {
    //     extractedSuggestions.push(option?.text);
    //   });
    return extractedSuggestions;
  };
  const searchCompany = async (keyword) => {
    try {
      let query = "";
      query = searchWithoutIndustry(keyword);
      const response = await searchCompanies({
        ...query,
        searchedWord: keyword,
      });
      let hits = response?.data?.hits?.hits?.map((hit) => hit?._source);

      console.log("hits/////////////////////////", hits[0]);
      history.push(`/unternehmen/${hits[0]?.slug || hits[0]?.id}`);
    } catch (error) {
      console.error(error);
    }
  };
  const getSearchSuggestions = async (word) => {
    try {
      const { data } = await searchSuggestions(searchSuggestionsQuery(word));
      const suggestedWords = extractSuggestions(data?.suggest);
      setSearchedSuggestions(suggestedWords);
    } catch (err) {
      console.log("Error in getting search suggestions : ", err);
    }
  };
  const handleChange = (event) => {
    setRenderDropDown(true);
    setSearchKeyword(event.target.value);
    getSearchSuggestions(event.target.value);
  };
  const renderDropdownOptions = () => {
    // For simplicity, let's assume you have a predefined list of options.
    return (
      <ul className="search-suggestions">
        {searchedSuggestions.map((option, index) => (
          <li
            key={index}
            onClick={() => {
              setRenderDropDown(false);
              searchCompany(option);
              // history.push(`/suchen?keyWord=${option}`);
              // window.location.reload();
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    );
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && searchKeyword) {
      history.push(`/suchen?keyWord=${searchKeyword}`);
      window.location.reload();
    }
  };
  const keyWordTrigger = (word) => {
    history.push(`/suchen?keyWord=${word}`);
    window.location.reload();
  };
  return (
    <div className="search-input">
      {icon && classIcon === "left" && (
        <span className="search-icon">{icon}</span>
      )}
      <input
        type={type}
        name={name}
        placeholder={placeholder || "Suche"}
        value={searchKeyword}
        onChange={(e) => handleChange(e)}
        onKeyDown={handleKeyDown}
      />
      {searchedSuggestions?.length && renderDropDown
        ? renderDropdownOptions()
        : null}
      {icon && classIcon === "right" && (
        <span className="search-icon">{icon}</span>
      )}
      {isError && <span className="error">{error}</span>}
    </div>
  );
};
export default SearchInput;
