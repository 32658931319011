import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import { useEffect, useState } from "react";

function PublicLayout({ children }) {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsSticky(scrollTop > 70);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header isSticky={isSticky} />
      <main>{children}</main>
      <Footer />
    </>
  );
}

export default PublicLayout;
