import API, { API_URL } from "../index";

export const login = async (body) => {
  return API.user.post(API_URL.LOGIN, body);
};
export const logout = async (body) => {
  return API.user.post(API_URL.LOGOUT, body);
};
export const verifyToken = async () => {
  return API.user.get(API_URL.VERIFY_TOKEN);
};
export const forget = async (body) => {
  return API.user.post(API_URL.FORGET, body);
};
export const confirmResetPassword = async (body) => {
  return API.user.post(API_URL.CONFIRM_RESET, body);
};
export const resetPassword = async (body) => {
  return API.user.post(API_URL.RESET_PASSWORD, body);
};
export const register = async (body) => {
  return API.user.post(API_URL.REGISTER, body);
};
export const getUser = async () => {
  return API.user.get(API_URL.GET_USER);
};

export const registerByAdmin = async (data) => {
  return await API.withHeaders.post(API_URL.REGISTER_BY_ADMIN, data);
};

export const subscribe = async (body) => {
  return API.user.post(API_URL.SUBSCRIBE, body);
};

export const contact = async (body) => {
  return API.user.post(API_URL.CONTACT, body);
};

export const getAllContacts = async () => {
  return API.user.get(API_URL.GET_ALL_CONTACTS);
};

export const booking = async (body) => {
  return API.user.post(API_URL.BOOKING, body);
};

export const checkExistingUser = async (body) => {
  return API.user.post(API_URL.CHECK_USER, body);
};
export const welcome = async (body) => {
  return API.user.post(API_URL.WELCOME, body);
};

export const getUsersByCompany = async (companyId) => {
  return API.user.get(API_URL.GET_USERS_BY_COMPANY(companyId));
};

export const updateUser = async (id, body) => {
  return API.withHeaders.put(API_URL.UPDATE_USER(id), body);
};
