import React from 'react';

const CrossSvg = ({ width }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width ? '26' : '12'} height={width ? '20' : '12'} viewBox='0 0 12 12' fill='none'>
      <path
        d='M10.9119 12L6.00386 7.0874L1.09582 12L0 10.9049L4.91576 6L0 1.09512L1.09582 0L6.00386 4.9126L10.9119 0.00771226L12 1.09512L7.09196 6L12 10.9049L10.9119 12Z'
        fill={width ? 'black' : '#3C3A3F'}
      />
    </svg>
  );
};

export default CrossSvg;
