import { createSlice } from "@reduxjs/toolkit";

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    recentCompanies: [],
    hotCompanies: [],
    categoryCompanies: [], // Existing state, unchanged
    companiesByCategory: {}, // New state to manage companies by industry ID
    allCompanies: [],
    industriesData: [],
    searchData: [],
    popularCompanies: [],
    companiesReels: [],
    recommendationsData: [],
    searchedCompaniesData: [], // New state for storing searched companies
    userLoggedIn: false,
    currentUserData: null,
    userCurrentCompany: [],
    allCompaniesSearchData: [],
    currentCompanyAds: [],
    currentCompanyUser: null,
    isLoading: true,
  },
  reducers: {
    setRecentCompanies: (state, action) => {
      state.recentCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setHotCompanies: (state, action) => {
      state.hotCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setCategoryCompanies: (state, action) => {
      state.categoryCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setIndustries: (state, action) => {
      state.industriesData = action.payload;
    },
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setPopularCompanies: (state, action) => {
      state.popularCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([...state.allCompanies, ...state.popularCompanies])
      );
    },
    setCompaniesReels: (state, action) => {
      state.companiesReels = action.payload;
    },
    addCompaniesByCategory: (state, action) => {
      const { industryId, companies } = action.payload;
      state.companiesByCategory[industryId] = companies;
      state.allCompanies = Array.from(
        new Set([...state.allCompanies, ...companies])
      );
    },
    setSearchedCompaniesData: (state, action) => {
      state.searchedCompaniesData = action.payload;
      // state.allCompanies = Array.from(
      //   new Set([
      //     ...state.recentCompanies,
      //     ...state.hotCompanies,
      //     ...state.categoryCompanies,
      //     ...state.popularCompanies,
      //     ...state.searchedCompaniesData, // Add searched companies to allCompanies
      //   ])
      // );
    },
    setRecommendationsData: (state, action) => {
      state.recommendationsData = action.payload;
    },
    setUserLoggedIn: (state, action) => {
      state.userLoggedIn = action.payload;
    },
    setUserCurrentCompany: (state, action) => {
      state.userCurrentCompany = action.payload;
    },
    setUpdateAllCompanies: (state, action) => {
      const updatedCompany = action.payload;

      const companyExists = state.allCompanies.some(
        (company) => company._id === updatedCompany._id
      );

      if (companyExists) {
        state.allCompanies = state.allCompanies.map((company) =>
          company._id === updatedCompany._id ? updatedCompany : company
        );
        // console.log("Company updated successfully.");
      } else {
        state.allCompanies.push(updatedCompany);
        console.log("New company added.");
      }
    },

    setUserData: (state, action) => {
      state.currentUserData = action.payload;
    },
    setAllCompaniesSearchData: (state, action) => {
      state.allCompaniesSearchData = action.payload;
    },
    setCurrentCompanyAds: (state, action) => {
      state.currentCompanyAds = action.payload;
    },
    setCurrentCompanyUser: (state, action) => {
      state.currentCompanyUser = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    }
    
  },
});

export const {
  setRecentCompanies,
  setHotCompanies,
  setCategoryCompanies,
  setIndustries,
  setSearchData,
  addCompaniesByCategory,
  setPopularCompanies,
  setCompaniesReels,
  setSearchedCompaniesData,
  setRecommendationsData,
  setUserLoggedIn,
  setUserCurrentCompany,
  setUserData,
  setAllCompaniesSearchData,
  setCurrentCompanyAds,
  setCurrentCompanyUser,
  setUpdateAllCompanies,
  setLoading
} = companiesSlice.actions;

export default companiesSlice.reducer;
