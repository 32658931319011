import React, { Suspense } from 'react';
import './PageLoader.scss'
const PageLoader = () => {
  return (
    <div className="linearActivity">
      <div className="indeterminate"></div>
    </div>
  );
};
export const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default PageLoader;
