import { createSlice } from '@reduxjs/toolkit';

const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobsData: [],
  },
  reducers: {
    setJobsData: (state, action) => {
      state.jobsData = action.payload;
    },
  },
});

export const { setJobsData } = jobsSlice.actions;

export default jobsSlice.reducer;
